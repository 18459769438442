import React, { useEffect, useRef } from 'react';
import ProjectCard from '../ProjectCard/ProjectCard';
import styles from './Portfolio.module.css';

const Portfolio = () => {
    const categoriesRef = useRef([]);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add(styles.visible);
                    }
                });
            },
            { threshold: 0.1 }
        );

        const currentCategories = categoriesRef.current;

        currentCategories.forEach((category) => {
            if (category) {
                observer.observe(category);
            }
        });

        return () => {
            currentCategories.forEach((category) => {
                if (category) {
                    observer.unobserve(category);
                }
            });
        };
    }, []);

    const categories = {
        Telegram: [
            { title: 'GIVEAWAY BOT SAKUTA', description: 'Bot for hosting giveaways on Telegram', link: 'https://t.me/giveaway_tw_bot' },
            { title: 'CRYPTO SCANNER', description: 'Tracking cryptocurrency wallets' },
            { title: 'CLICKER APP', description: 'Full stack crypto clicker game' },
            { title: 'Job Reviews', description: 'System for submitting job reviews' },
        ],
        Discord: [
            { title: 'GIVEAWAY BOT', description: 'Bot for hosting giveaways on Discord' },
            { title: 'LOGGER', description: 'Logging all actions on the server' },
            { title: 'INVITE FAMILY', description: 'Allows processing family applications' },
            { title: 'Role Assignment', description: 'Automatic role assignment' },
            { title: 'Server Moderator', description: 'Automatic user blocking for prohibited words' },
            { title: 'Event Gathering', description: 'Allows registration and organization of participants for gaming events' },
        ],
        Web: [
            { title: 'ANILIS', description: 'Anime streaming service | Full Stack', link: 'https://anilis.fun' },
            { title: 'GIVEAWAY BOT SAKUTA API', description: 'API for giveaway bot' },
            { title: 'MBDOU №20', description: 'Website for a state preschool institution' },
        ],
        Desktop: [
            { title: 'HOMIXIDE BINDER', description: 'An application for automating work with Majestic RP' },
        ],
        Mobile: [
            { title: 'Reviewer', description: 'Allows users to rate establishments and people, as well as leave reviews' },
            { title: 'News portal', description: 'Сity news portal that gathers information from various sources' },
        ]
    };

    return (
        <section className={styles.portfolio}>
            <h2 className={styles.title}>Portfolio</h2>
            <div className={styles.content}>
                {Object.keys(categories).map((category, index) => (
                    <div
                        key={index}
                        className={`${styles.category} ${styles.hidden}`}
                        ref={(el) => (categoriesRef.current[index] = el)}
                        style={{ animationDelay: `${index * 0.3}s` }}
                    >
                        <h3 className={styles.categoryTitle}>{category}</h3>
                        <div className={styles.grid}>
                            {categories[category].map((project, idx) => (
                                <ProjectCard key={idx} title={project.title} description={project.description} link={project.link} />
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        </section>
    );
};

export default Portfolio;
