import React, { useEffect, useState } from 'react';
import styles from './Hero.module.css';
import lightGradient from '../../assets/gradient.svg';
import darkGradient from '../../assets/gradient-dark.svg';

const Hero = () => {
    const [gradient, setGradient] = useState(lightGradient);

    useEffect(() => {
        const currentTheme = document.documentElement.getAttribute('data-theme');
        setGradient(currentTheme === 'dark' ? darkGradient : lightGradient);

        const observer = new MutationObserver(() => {
            const updatedTheme = document.documentElement.getAttribute('data-theme');
            setGradient(updatedTheme === 'dark' ? darkGradient : lightGradient);
        });

        observer.observe(document.documentElement, { attributes: true, attributeFilter: ['data-theme'] });

        return () => observer.disconnect();
    }, []);

    return (
        <section className={styles.hero} style={{ backgroundImage: `url(${gradient})` }}>
            <div className={styles.centerText}>
                <h1 className={styles.title}>
                    Your <span className={styles.highlight}>Vision</span> My <span className={styles.highlight}>Code</span>
                </h1>
                <p className={styles.subtitle}>Our Success.</p>
            </div>
            <div className={styles.about}>
                Hi, my name is Evgeny.<br />
                I am a Junior Full Stack Developer from Russia.<br />
                I work with WEB applications, most of all I like to write backend and various bots.
            </div>
        </section>
    );
};

export default Hero;
