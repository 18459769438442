import { useEffect } from "react";

const useTheme = () => {
    useEffect(() => {
        const currentHour = new Date().getHours();
        const theme = currentHour >= 17 || currentHour < 6 ? 'dark' : 'light';
        document.documentElement.setAttribute('data-theme', theme);
    }, []);
};

export default useTheme;