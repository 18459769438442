import React from 'react';
import Header from './components/Header/Header';
import Hero from './components/Hero/Hero';
import Portfolio from './components/Portfolio/Portfolio';
import useTheme from './hooks/useTheme';
import './styles/reset.css';
import './styles/global.css';

const App = () => {
  useTheme();

  return (
    <div className='app'>
      <Header />
      <Hero />
      <Portfolio />
    </div>
  );
};

export default App;
