import React from 'react';
import styles from './ProjectCard.module.css';

const ProjectCard = ({ title, description, link }) => {
    return (
        <a href={link} className={styles.card} target="_blank" rel="noopener noreferrer">
            <h3 className={styles.title}>{title}</h3>
            <p className={styles.description}>{description}</p>
        </a>
    );
};

export default ProjectCard;
