import React from 'react';
import styles from './Header.module.css';

const Header = () => {
    return (
        <header className={styles.header}>
            <div className={styles.logo}>WHXTELXS.DEV</div>
            <a href="https://t.me/LISIKDEV" className={styles.link} target="_blank" rel="noopener noreferrer">
                T.ME/LISIKDEV
            </a>
        </header>
    );
};

export default Header;
